<template>
  <div class="member-register-container">
    <v-row class="member-highlights-container">
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-img src="https://ebikeworldfederation.com/images/member/EBWF_Mitgliedschaft_Contentbild_Startplatz_01.jpg">
            <h1 class="white--text">Gesicherter Startplatz bei allen Events des E-Bike Weltverbandes</h1>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-img src="https://ebikeworldfederation.com/images/infobar/new/EBWF_Mitgliedschaft_Contentbild_Mud_Buff_01.jpg">
            <h1>1x Wir sind E-Bike – Multifunktionstuch + EBWF-Edition - Mudguard</h1>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-img src="https://ebikeworldfederation.com/images/infobar/new/EBWF_Mitgliedschaft_Contentbild_Schutzbrief_01.jpg">
            <h1 class="white--text">20 % Exklusive Ermäßigung auf E-Bike Vollkasko Versicherung von Hepster</h1>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-img src="https://ebikeworldfederation.com/images/member/EBWF_Mitgliedschaft_Contentbild_Produkte_01.jpg">
            <h1>20 % Ermäßigung auf alle Produkte im Shop des E-Bike Weltverbandes</h1>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="member-register-form-container ml-1 mr-1 mt-4">
      <v-row class="align-center justify-center">
        <v-col cols="12" sm="12" md="11" lg="11" xl="10">
          <v-row>
            <v-col cols="12" class="text-left">
              <h1 class="member-title">EBWF Mitgliedschaft sichern</h1>
            </v-col>
            <v-col cols="12">
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field solo
                        label="Vorname"
                        v-model="register.firstName"
                        :rules="rules"
                        validate-on-blur required
                    />
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field solo
                        label="Nachname"
                        v-model="register.lastName"
                        :rules="rules"
                        validate-on-blur required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field v-model="register.birthday" label="Geburtstag" type="date" solo
                                  required :rules="rules" validate-on-blur/>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-select v-model="register.sex" :items="sexes" label="Geschlecht" solo
                              persistent-hint :rules="rules" required item-text="label" item-value="value"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field solo
                        label="Straße und Hausnummer"
                        v-model="register.street"
                        :rules="rules"
                        validate-on-blur required
                    />
                  </v-col>
                  <v-col cols="12" sm="3" class="pt-0 pb-0">
                    <v-text-field solo
                        label="Postleitzahl"
                        v-model="register.zip"
                        :rules="rules"
                        validate-on-blur required
                    />
                  </v-col>
                  <v-col cols="12" sm="3" class="pt-0 pb-0">
                    <v-text-field solo
                        label="Ort"
                        v-model="register.city"
                        :rules="rules"
                        validate-on-blur required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field label="Promotion code (Optional)" solo
                                  v-model="register.promotionCode"/>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-select v-model="register.nationality" :items="countries" label="Nationalität" solo
                              persistent-hint :rules="rules" required item-text="label" item-value="value"
                    />
                  </v-col>
                </v-row>
                <v-row class="text-left" style="margin-block: 14px;">
                  <v-col cols="12">
                    <div class="register-shirt-outer-container">
                      <v-row>
                        <v-col cols="12" sm="12" xl="1" class="register-shirt-container">
                          <span class="register-shirt-title">T-Shirt</span>
                        </v-col>
                        <v-col cols="12" sm="12" xl="7" class="register-shirt-container">
                          <span class="register-shirt-subtitle">Gewünschte Größe</span>
                          <v-btn @click="selectShirtSize('S')" elevation="2" color="white"
                                 :class="['register-shirt-button', 'mt-1 mb-1', {'selected': isShirtSize('S')},
                                 {'error': checkIfShirtSizeIsSelected()}]">
                            S
                          </v-btn>
                          <v-btn @click="selectShirtSize('M')" elevation="2" color="white"
                                 :class="['register-shirt-button', 'mt-1 mb-1', {'selected': isShirtSize('M')},
                                 {'error': checkIfShirtSizeIsSelected()}]">
                            M
                          </v-btn>
                          <v-btn @click="selectShirtSize('L')" elevation="2" color="white"
                                 :class="['register-shirt-button', 'mt-1 mb-1', {'selected': isShirtSize('L')},
                                 {'error': checkIfShirtSizeIsSelected()}]">
                            L
                          </v-btn>
                          <v-btn @click="selectShirtSize('XL')" elevation="2" color="white"
                                 :class="['register-shirt-button', {'selected': isShirtSize('XL')},
                                 {'error': checkIfShirtSizeIsSelected()}]">
                            XL
                          </v-btn>
                          <v-btn @click="selectShirtSize('XXL')" elevation="2" color="white"
                                 :class="['register-shirt-button', 'mt-1 mb-1', {'selected': isShirtSize('XXL')},
                                 {'error': checkIfShirtSizeIsSelected()}]">
                            XXL
                          </v-btn>
                          <v-btn @click="selectShirtSize('3XL')" elevation="2" color="white"
                                 :class="['register-shirt-button', 'mt-1 mb-1', {'selected': isShirtSize('3XL')},
                                 {'error': checkIfShirtSizeIsSelected()}]">
                            3XL
                          </v-btn>
                          <v-btn @click="selectShirtSize('4XL')" elevation="2" color="white"
                                 :class="['register-shirt-button', 'mt-1 mb-1', {'selected': isShirtSize('4XL')},
                                 {'error': checkIfShirtSizeIsSelected()}]">
                            4XL
                          </v-btn>
                        </v-col>
                        <!--<v-col cols="12" sm="12" xl="4" class="register-shirt-container">
                          <span class="register-shirt-subtitle">Gewünschte Farbe</span>
                          <v-btn @click="selectShirtColor('black')" elevation="2" color="white"
                                 :class="['register-shirt-button', 'mt-1 mb-1', {'selected': isShirtColor('black')},
                                 {'error': checkIfShirtColorIsSelected()}]">
                            Schwarz
                          </v-btn>
                          <v-btn @click="selectShirtColor('white')" elevation="2" color="white"
                                 :class="['register-shirt-button', 'mt-1 mb-1', {'selected': isShirtColor('white')},
                                 {'error': checkIfShirtColorIsSelected()}]">
                            Weiß
                          </v-btn>
                        </v-col>-->
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" class="pb-0">
                    <v-text-field solo
                                  label="E-Mail Adresse"
                                  v-model="register.mail"
                                  :rules="rules"
                                  validate-on-blur required
                    />
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    <v-text-field solo
                                  label="E-Mail Adresse wiederholen"
                                  v-model="register.repeatMail"
                                  :rules="rulesRepeat"
                                  validate-on-blur required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox color="primary"
                                v-model="register.agbRules"
                                :rules="[v => !!v || 'Pflichtfeld!']"  required>
                      <template v-slot:label>
                        Hiermit akzeptiere ich die Bestimmungen "Mitgliedschaft E-Bike World Federation"
                        <a
                            @click.stop
                            target="_blank"
                            href="https://ebikeworldfederation.com/documents/AGB_EBWF_Mitgliedschaft.pdf"
                            class="ml-2 text-decoration-none"
                        >
                          Download
                        </a>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-left">
                    <v-btn color="primary" @click="submit(true)" class="insurance-button"
                           :loading="loading || checkCode" :disabled="loading">
                      <span v-if="!hasPromotionCode">Mitgliedschaft für € 39,- im Jahr beantragen</span>
                      <span v-else>
                        Mitgliedschaft für € {{39 - (39*(promotionCode.discount/100))}},- im Jahr beantragen
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="loading" max-width="400px" :fullscreen="$vuetify.breakpoint.smAndDown" persistent>
      <v-card>
        <v-card-text>
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-center" cols="12">
              <h3>Daten werden übermittelt...</h3>
            </v-col>
            <v-col cols="12">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.response" scrollable
              max-width="800px"
              :fullscreen="$vuetify.breakpoint.xsOnly"
              transition="slide-x-reverse-transition"
              content-class="register-dialog-container">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pb-0 pt-0">
              <img v-lazyimg="'https://www.ebikewm.com/images/EBWM_Register_Success.jpg'">
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <h1>
                Sie haben sich erfolgreich registriert. <br/><br/>
                In Kürze werden Sie eine Bestätigung mit weiteren Informationen erhalten.
              </h1>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog.response = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="snackbar.timeout"
      :bottom="snackbar.bottom"
      :color="snackbar.color"
      v-model="snackbar.value">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon style="margin: 0 !important;"
               @click="snackbar.value = false">
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import {mdiCheck, mdiCalendar, mdiClose, mdiInformation, mdiPlus, mdiMinus} from '@mdi/js';
  import InsurancePricesUtils from "../../utils/InsurancePricesUtils";
  import CountryUtils from "../../utils/CountryUtils";
  export default {
    mixins: [
      InsurancePricesUtils, CountryUtils
    ],
    props: {
      priceRangePackage: null,
    },
    data() {
      return {
        snackbar: {
          value: false,
          text: '',
          color: 'success',
          timeout: 10000,
          bottom: true,
        },
        icons: {
          check: mdiCheck,
          calendar: mdiCalendar,
          close: mdiClose,
          info: mdiInformation,
          add: mdiPlus,
          minus: mdiMinus
        },
        valid: true,
        validate: false,
        loading: false,
        menu: {
          birthday: false,
        },
        success: false,
        register: {
          firstName: null,
          lastName: null,
          birthday: null,
          sex: null,
          nationality: null,
          promotionCode: null,
          street: null,
          zip: null,
          city: null,
          shirtSize: null,
          shirtColor: null,
          mail: null,
          repeatMail: null,
          ebwfRules: false,
          insuranceRules: false,
          misc: null,
          agbRules: false
        },
        dialog: {
          response: false,
        },
        stripe: {
          init: undefined,
        },
        scrollOptions: {
          duration: 250,
          offset: 80,
          easing: 'easeInOutCubic',
        },
        hasPromotionCode: false,
        promotionCode: null,
        checkCode: false,
      }
    },
    mounted() {
      // eslint-disable-next-line no-undef
      this.stripe.init = Stripe('pk_live_XYBYmtLKllz5NR5BfoNVeSbR00gtSDQ0fa');
      // eslint-disable-next-line no-undef
      // this.stripe.init = Stripe('pk_test_O8UmGuspsJQmozJuSBpqcPzP003KCzCj33');
    },
    created() {
      if (this.$route.query.success !== undefined) {
        this.success = this.$route.query.success === 'true';
        if (this.$route.query.session_id !== undefined && !this.success) {
          this.$http.get('member/cancel/session?sessionId='.concat(this.$route.query.session_id));
        }
        this.dialog.response = true;
        this.$router.replace({});
        if (this.success) {
          // eslint-disable-next-line no-undef
          dataLayer.push({'event': 'purchase.completed'});
        } else {
          // eslint-disable-next-line no-undef
          dataLayer.push({'event': 'purchase.failed'});
        }
      }
    },
    watch: {
      'register.promotionCode'() {
        if (typeof this.register.promotionCode !== 'undefined' && this.register.promotionCode !== null
            && this.register.promotionCode.length > 11) {
          this.checkPromotionCode();
        } else {
          this.hasPromotionCode = false;
          this.promotionCode = null;
        }
      }
    },
    methods: {
      reverseDate(date) {
        const [day, month, year] = date.split('.')
        return `${year}-${month}-${day}`;
      },
      submit() {
        try {
          this.validate = true;
          if (!this.$refs.form.validate() || this.checkIfShirtSizeIsSelected()) { // || this.checkIfShirtColorIsSelected()
            this.snackbar.text = 'Nicht alle Pflichtfelder wurden ausgefüllt!';
            this.snackbar.color = 'error';
            this.snackbar.timeout = 10000;
            this.snackbar.value = true;
          } else if (!this.checkDateValidity()) {
            this.snackbar.text = 'Geburtsdatum wurde nicht korrekt eingetragen!';
            this.snackbar.color = 'error';
            this.snackbar.timeout = 10000;
            this.snackbar.value = true;
          } else {
            this.loading = true;
            const register = {
              firstName: this.register.firstName,
              lastName: this.register.lastName,
              birthday: this.register.birthday,
              sex: this.register.sex,
              nationality: this.register.nationality,
              promotionCode: this.register.promotionCode === '' ? null : this.register.promotionCode,
              street: this.register.street,
              zip: this.register.zip,
              city: this.register.city,
              mail: this.register.mail,
              shirtSize: this.register.shirtSize,
              shirtColor: this.register.shirtColor,
              misc: this.register.misc
            }
            this.$http.post('member', register).then((data) => {
              if (data.body.registration === 'MAIL ADDRESS ALREADY USED') {
                this.snackbar.text = 'Diese E-Mail-Adresse wurde bereits angemeldet!';
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
                this.loading = false;
              } else if (data.body.registration === 'PROMOTION CODE NOT FOUND') {
                this.snackbar.text = 'Promotion code wurde nicht gefgunden!';
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
                this.loading = false;
              } else if (data.body.registration === 'PROMOTION CODE ALREADY USED') {
                this.snackbar.text = 'Promotion code wurde bereits verwendet!';
                this.snackbar.color = 'error';
                this.snackbar.timeout = 10000;
                this.snackbar.value = true;
                this.loading = false;
              }
              if (data.body.registration === 'REGISTERED - COMPLETED') {
                this.dialog.response = true;
                // eslint-disable-next-line no-undef
                dataLayer.push({'event': 'purchase.completed'});
                this.resetForm();
                this.loading = false;
              } else if (data.body.registration === 'REGISTERED') {
                this.stripe.init.redirectToCheckout({
                  sessionId: data.body.session.id
                }).then((data) => {
                  this.loading = false;
                  if (data.error) {
                    this.snackbar.text = 'Registrierung konnte nicht abgeschlossen werden!';
                    this.snackbar.color = 'error';
                    this.snackbar.timeout = 10000;
                    this.snackbar.value = true;
                  }
                }, () => {
                  this.loading = false;
                  this.snackbar.text = 'Registrierung konnte nicht abgeschlossen werden!';
                  this.snackbar.color = 'error';
                  this.snackbar.timeout = 10000;
                  this.snackbar.value = true;
                });
              }
            }, (data) => {
              this.loading = false;
              // eslint-disable-next-line no-console
              console.error(data);
              this.snackbar.text = 'Registrierung konnte nicht abgeschlossen werden!';
              this.snackbar.color = 'error';
              this.snackbar.timeout = 10000;
              this.snackbar.value = true;
            });
          }
        } catch (err) {
          this.loading = false;
          this.snackbar.text = 'Registrierung konnte nicht abgeschlossen werden!';
          this.snackbar.color = 'error';
          this.snackbar.timeout = 10000;
          this.snackbar.value = true;
          this.$http.post('error', {
            errorMessage: err,
            url: window.location.href
          });
        }
      },
      resetForm() {
        this.register = {
          firstName: null,
          lastName: null,
          birthday: null,
          sex: null,
          nationality: null,
          promotionCode: null,
          street: null,
          zip: null,
          city: null,
          shirtSize: null,
          shirtColor: null,
          mail: null,
          repeatMail: null,
          ebwfRules: false,
          insuranceRules: false,
          misc: null,
        };
        this.validate = false;
        this.$refs.form.resetValidation();
      },
      checkDateValidity() {
        let valid = true;
        if (this.register.birthday === null) {
          valid = false;
        } else if (this.register.birthday === '') {
          valid = false;
        } else {
          try {
            const birthday = this.register.birthday.split('-');
            if (Number(birthday[0]) < Number(1900)) {
              valid = false;
            }
          } catch (error) {
            valid = false;
            // eslint-disable-next-line no-console
            console.error(error.stack);
          }
        }
        return valid;
      },
      selectShirtSize(size) {
        this.register.shirtSize = size;
      },
      isShirtSize(size) {
        if (this.register.shirtSize === null) {
          return false;
        }
        return this.register.shirtSize === size;
      },
      checkIfShirtSizeIsSelected() {
        return this.register.shirtSize === null && this.validate;
      },
      selectShirtColor(color) {
        this.register.shirtColor = color;
      },
      isShirtColor(color) {
        if (this.register.shirtColor === null) {
          return false;
        }
        return this.register.shirtColor === color;
      },
      checkIfShirtColorIsSelected() {
        return this.register.shirtColor === null && this.validate;
      },
      checkPromotionCode() {
        this.checkCode = true;
        this.$http.get('member/promotion/check?promotionCode='.concat(this.register.promotionCode))
            .then((data) => {
              // eslint-disable-next-line no-console
              console.log(data);
              this.hasPromotionCode = data.body.promotionCodes.length > 0
                  && data.body.promotionCodes[0].code === this.register.promotionCode;
              this.promotionCode = this.hasPromotionCode ? data.body.promotionCodes[0] : null;
              // eslint-disable-next-line no-console
              console.log(this.promotionCode);
              this.checkCode = false;
        }, (data) => {
          this.checkCode = false;
          // eslint-disable-next-line no-console
          console.error(data);
        });
      },
    },
    computed: {
      rules() {
        return [val => (val || '').length > 0 || this.$t('required')];
      },
      countries() {
        return this.isoCountriesDE;
      },
      sexes() {
        return [
          {label: 'Weiblich', value: 'women'},
          {label: 'Männlich', value: 'men'}
        ];
      },
      rulesRepeat() {
        return [
          (val) => (val || '').length > 0 || this.$t('required'),
          (v) => v === this.register.mail || 'Die E-Mail Adressen müssen identisch sein.',
        ];
      },
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";
  .member-register-container {
    .member-highlights-container {
      margin-top: -110px;
      margin-bottom: 50px;
      .v-card {
        background-color: $basic-gray;
        border-radius: 10px;
        height: 155px;
        @include MQ(XL) {
          height: 255px !important;
        }
        h1 {
          padding: 10px;
          font-size: 1.6rem;
          line-height: 1.7rem;
          font-weight: 600;
          font-style: italic;
          text-align: left;
          @include MQ(XS) {
            font-size: 1.3rem;
            line-height: 1.4rem;
          }
          @include MQ(S) {
            font-size: 1.3rem;
            line-height: 1.4rem;
          }
          @include MQ(M) {
            font-size: 1.1rem;
            line-height: 1.2rem;
          }
          @include MQ(L) {
            font-size: 1.3rem;
            line-height: 1.4rem;
          }
        }
        .v-image {
          height: 155px !important;
          @include MQ(XL) {
            height: 255px !important;
          }
        }
      }
    }
    .member-register-form-container {
      background-color: $basic-gray;
      border-radius: 10px;
      padding: 30px 10px;
      .v-input {
        margin-bottom: 0;
      }
      .insurance-button {
        height: 100% !important;
        white-space: unset;
        float: left;
        border-radius: 10px;
        .v-btn__content {
          height: 100% !important;
          display: contents;
        }
      }
      .register-shirt-outer-container {
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        background-color: white;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
        .register-shirt-button {
          min-width: 40px !important;
          margin-bottom: 0 !important;
        }
        .register-shirt-container {
          display: flex;
          align-items: center;
          @include MQ(XS) {
            display: block;
          }
        }
        .register-shirt-title {
          font-size: 1.4rem;
        }
        .register-shirt-subtitle {
          margin-right: 10px;
          font-size: 14px;
          @include MQ(XS) {
            display: block;
          }
        }
      }
      .v-input {
        &.error--text {
          .v-label{
            color: #ff5252 !important;
          }
          .v-input__slot {
            input {
              color: #ff5252 !important;
            }
          }
        }
        .v-input__slot {
          border-radius: 10px;
          margin-bottom: 3px;
        }
        .v-text-field__details {
          margin-bottom: 0;
        }
      }
    }
    .v-input {
      .v-label {
        font-size: 16px !important;
      }
    }
    .accept-rules {
      .v-input {
        margin: 10px 0 0 !important;
        .v-label {
          height: 100% !important;
        }
      }
    }
  }
  .insurance-info {
    text-align: left;
  }
</style>
