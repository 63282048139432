<template>
  <div class="member">
    <div class="header">
      <v-tabs v-model="tab" background-color="transparent" v-if="$vuetify.breakpoint.mdAndUp"
              :class="[{'header-tabs-navigation': navigation.scrolled}]">
        <v-tab v-for="item in tabs" :key="item.value">
          {{item.label}}
        </v-tab>
      </v-tabs>
      <v-select :items="selectTabs" item-text="label" item-value="value"
                :class="[{'header-select-tabs-navigation':navigation.scrolled}]"
                v-model="tab" single-line v-else/>
      <div class="mobile-wrapper">
        <div class="header-background"
             :style="{backgroundImage: 'url(https://ebikeworldfederation.com/images/member/EBWF_MITGLIED_Headerbild_01.jpg)'}">
          <div class="header-title-container" v-if="tab === 0">
            <h1>MITGLIED WERDEN <br/> IM E-BIKE WELTVERBAND</h1>
            <h3>Jeder kann Teil der größte E-Bike Familie sein und von viele Vorteilen profitieren.</h3>
            <h2>Wir sind E-Bike. Everyone a winner.</h2>
          </div>
          <div class="header-title-container" v-else-if="tab === 1">
            <h1>ALLES WISSENSWERTE <br/> RUND UM DEINE MITGLIEDSCHAFT</h1>
          </div>
          <div class="header-title-container" v-else-if="tab === 2">
            <h1>JETZT MITGLIED WERDEN</h1>
            <h3 style="max-width: 100%">Mitgliedschaft im E-Bike Weltverband</h3>
            <h2>Highlights für Dich:</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="member-container member-container-primary"/>
    <v-main class="member-container">
      <v-container>
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="12" md="12" lg="11" xl="10" class="pa-0">
            <v-tabs-items v-model="tab" style="overflow: visible">
              <v-tab-item v-for="item in tabs" :key="item.value" :value="item.value">
                <v-container>
                  <v-row style="margin-top: 30px;">
                    <v-col cols="12" class="pt-0" v-if="item.value === 0">
                      <Services/>
                    </v-col>
                    <v-col cols="12" v-if="item.value === 1">
                      <f-a-q/>
                    </v-col>
                    <v-col cols="12" v-if="item.value === 2">
                      <Register/>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <div class="member-bottom-register-container" v-if="navigation.scrolled && navigation.scrollingDown">
      <v-main>
        <v-container>
          <v-row class="align-center justify-center">
            <v-col cols="12" sm="12" md="12" lg="11" xl="10">
              <v-row>
                <v-col class="member-bottom-register-price text-left pl-3" cols="6" order="2" sm="3" order-sm="1"
                       md="3" lg="3" xl="2">
                  pro Jahr: <strong>€ 39,-</strong>
                  <!-- <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="white">{{icons.info}}</v-icon>
                    </template>
                    <span>
                      <strong>Preiszusammensetzung:</strong><br/><br/>
                      Durch das Unternehmen E-Bike Federation GmbH im Gesamtwert von Euro 52,00 inkl. MwSt.: 1 x
                      Offizielles Hoodie des E-Bike Weltverbandes für das Jahr 2021 (limited Edition), Abo des
                      führenden E-Bike Magazins ElektroRad mit 4 Ausgaben (Print- oder Digital) sowie Ermäßigungen.
                      <br/><br/>
                      Durch das Versicherungsunternehmen MOINsure GmbH (unter der Marke hepster) mit einem Wert von
                      Euro 7,00 inkl. Versicherungssteuer (11 % für Österreich/ 19 % für Deutschland: E-Bike
                      Schutzbrief Versicherung gegen Panne und Unfall sowie Ermäßigung auf E-Bike Schutz Versicherung
                      gegen Beschädigung, Verschleiß und Diebstahl.
                    </span>
                  </v-tooltip> -->
                </v-col>
                <v-col class="text-left member-bottom-register-text" cols="12" order="1" sm="6" order-sm="2" md="6"
                       lg="7" xl="8">
                  Mitglied werden, in der Größten E-Bike-Familie der Welt
                </v-col>
                <v-col class="text-right pr-3" cols="6" order="3" sm="3" order-sm="3" md="3" lg="2" xl="2">
                  <v-btn @click="tab = 2">
                    Anmelden
                    <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                      <path style="fill:black;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                            C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                            C31.4,8.2,31.4,7.9,31.3,7.7"/>
                    </svg>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </div>
    <v-dialog v-model="dialog.response" scrollable
              max-width="800px"
              :fullscreen="$vuetify.breakpoint.xsOnly"
              transition="slide-x-reverse-transition"
              content-class="register-dialog-container">
      <v-card>
        <v-card-text>
          <v-row v-if="success">
            <v-col cols="12" class="pb-0 pt-0">
              <img v-lazyimg="'https://www.ebikewm.com/images/EBWM_Register_Success.jpg'">
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <h1>
                Sie haben sich erfolgreich registriert. <br/><br/>
                In Kürze werden Sie eine Bestätigung mit weiteren Informationen erhalten.
              </h1>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <img v-lazyimg="'https://www.ebikewm.com/images/EBWM_Register_Error.jpg'">
            </v-col>
            <v-col cols="12">
              <h1>
                Während der Bezahlung ist ein Fehler aufgetreten. <br/><br/>
                Bitte kontaktieren Sie unser Support Team.
              </h1>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialog.response = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mdiChevronRight, mdiInformation} from '@mdi/js';
  import Services from "../components/member/Services";
  import FAQ from "../components/member/FAQ";
  import Register from "../components/member/Register";
  import InsurancePricesUtils from "../utils/InsurancePricesUtils";

  export default {
    name: 'member',
    mixins: [ InsurancePricesUtils ],
    components: {
      Services, FAQ, Register,
    },
    metaInfo() {
      return {
        title: "E-Bike World Federation | Mitgliedschaft",
        meta: [
          { name: 'description', content:  'EBWF MITGLIEDSCHAFT▷inkl. E-Bike Rund-um-Schutz \n'
              + '✓E-Bike Schutz gegen Beschädigung, Verschleiß & Diebstahl. \n'
              + '✚Sorglos mit großem Wert ➨ und kleinem Preis genießen!' },
          { property: 'og:title', content: "E-Bike World Federation | Mitgliedschaft" },
          { property: 'og:site_name', content: 'E-Bike World Federation' },
          { property: 'og:type', content: 'website' },
          { name: 'robots', content: 'index,follow' }
        ]
      }
    },
    data () {
      return {
        tab: 0,
        dialog: {
          response: false,
        },
        icons: {
          right: mdiChevronRight,
          info: mdiInformation,
        },
        success: false,
        navigation: {
          scrolled: false,
          position: 0,
          scrollingDown: false,
        },
      }
    },
    created() {
      if (this.$route.query.success !== undefined) {
        this.success = this.$route.query.success === 'true';
        if (this.$route.query.session_id !== undefined && !this.success) {
          this.$http.get('member/cancel/session?sessionId='.concat(this.$route.query.session_id));
        }
        this.dialog.response = true;
        this.$router.replace({});
        if (this.success) {
          // eslint-disable-next-line no-undef
          dataLayer.push({'event': 'purchase.completed'});
        } else {
          // eslint-disable-next-line no-undef
          dataLayer.push({'event': 'purchase.failed'});
        }
      }
    },
    mounted() {
      window.addEventListener('scroll', () => {
        if (!this.isIE11()) {
          this.navigation.scrolled = window.scrollY > 50;
          this.navigation.scrollingDown = this.navigation.position < window.scrollY;
          this.navigation.position = window.scrollY;
        } else {
          this.navigation.scrolled = window.pageYOffset > 50;
          this.navigation.scrollingDown = this.navigation.position < window.scrollY;
          this.navigation.position = window.pageYOffset;
        }
      });
      this.globalEvents.$on('goToElement', (selector) => {
        if (selector !== undefined) {
          this.$vuetify.goTo(selector, {
            duration: 0,
            offset: 70,
            easing: 'easeInOutCubic',
          });
        }
      });
      this.globalEvents.$on('goToCalculatePrice',() => {
        this.tab = 2;
      });
    },
    methods: {
      isIE11() {
        return window.navigator.userAgent.indexOf('Trident/7.0') > -1;
      },
    },
    computed: {
      tabs() {
        return [
          {value: 0, label: 'Mitglied werden'},
          {value: 1, label: 'Häufig gestellte Fragen'},
          {value: 2, label: 'Mitgliedschaft beantragen'}
        ];
      },
      selectTabs() {
        return [
          {value: 0, label: 'Mitglied werden'},
          {value: 1, label: 'Häufig gestellte Fragen'},
          {value: 2, label: 'Mitgliedschaft beantragen'}
        ];
      },
    }
  }
</script>

<style lang="scss">
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";
  .member {
    .header {
      position: relative;
      height: 600px;

      .v-tabs {
        position: fixed;
        top: 20px;
        left: 50%;
        z-index: 101;
        transform: translateX(-50%);
        display: inline-block;
        width: auto;
        &.header-tabs-navigation {
          top: 12px;
        }
        .v-tabs-slider-wrapper {
          display: none;
        }
        .v-tab {
          text-transform: none;
          height: 40px;
          color: $primary-text !important;
          border-radius: 16px !important;
          &::before {
            border-radius: 16px !important;
          }
        }
        .v-tab--active {
          color: white !important;
          background-color: $primary;
          border-radius: 16px;
        }
      }
      .v-select {
        position: fixed;
        top: 80px;
        left: 50%;
        z-index: 101;
        transform: translateX(-50%);
        display: inline-block;
        width: 165px;
        height: 40px;
        font-size: 12px;
        color: white !important;
        caret-color: white !important;
        border-radius: 16px !important;
        &.header-select-tabs-navigation {
          top: 0;
          padding-top: 11px;
        }
        .v-input__slot {
          background-color: $primary;
          border-radius: 16px;
          padding-left: 8px;
        }
        .v-input__append-inner {
          padding-left: 0 !important;
        }
        .v-select__selection--comma {
          margin: 7px 0 7px 0 !important;
          text-overflow: unset !important;
          max-width: 100%;
          width: 100%;
          text-align: center;
          color: white;
        }
        .v-icon {
          color: white !important;
        }
        .v-input__slot {
          &::before {
            display: none !important;
            border: none !important;
          }
          &::after {
            display: none !important;
            border: none !important;
          }
        }
        .v-text-field__details {
          display: none;
        }
        &.header-tabs-navigation {
          top: 12px;
        }
      }

      .header-background {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 600px;
        position: fixed;
        z-index: 0;

        .header-title-container {
          text-shadow: 2px 2px 4px #000000;
          position: absolute;
          bottom: 50px;
          left: 50%;
          transform: translateX(-50%);
          @include MQ(XS) {
            bottom: 95px;
            min-width: 300px;
          }
          @include MQ(S) {
            bottom: 95px;
            min-width: 600px;
          }
          @include MQ(M) {
            bottom: 25px;
            min-width: 800px;
          }
          @include MQ(L) {
            bottom: 35px;
          }

          h1 {
            font-style: italic;
            font-size: 72px;
            line-height: 72px;
            span {
              color: $primary;
            }

            @include MQ(XS) {
              font-size: 35px;
              line-height: 38px;
              margin-bottom: 10px;
            }
            @include MQ(S) {
              font-size: 48px;
              line-height: 48px;
              margin-bottom: 10px;
            }
            @include MQ(M) {
              font-size: 48px;
              line-height: 48px;
            }
            @include MQ(L) {
              font-size: 62px;
              line-height: 62px;
            }
          }
          h1, h2, h3, p {
            color: white;
            text-align: center;
          }
          h3 {
            font-weight: 400;
          }
          p {
            font-size: 13px;
          }
          h2 {
            font-style: italic;
          }
        }
      }
    }
    .member-container {
      position: relative;
      background: white;
      &.member-container-primary {
        background-color: $primary;
        color: white;
        padding-top: 20px;
        padding-bottom: 40px;
      }
      .member-services-slogan-container {
        font-size: 20px;
        max-width: 600px;
        text-align: left;
        position: relative;
        display: inline-block;
        @include MQ(XS) {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
      .member-overview-container {
        background-color: $basic-gray;
        margin-top: -90px;
        text-align: left;
        border-radius: 10px;
        table {
          color: $primary-text;
          font-weight: 600;
        }
        .highlight {
          color: $primary;
        }
      }
      .member-title {
        font-weight: 600;
        font-style: italic;
        font-size: 2.4rem;
        line-height: 2.4rem;
        letter-spacing: 0;
        color: $primary-text;
        word-break: keep-all !important;
        .member-title-primary {
          color: $primary;
        }
        .member-title-supersize {
          color: $primary;
          font-size: 4.5rem;
          line-height: 4.5rem;
        }
        &.member-title-distance {
          @include MQ(XS) {
            margin-bottom: 20px;
          }
          @include MQ(S) {
            margin-bottom: 20px;
          }
          @include MQ(M) {
            margin-bottom: 40px;
          }
          @include MQ(L) {
            margin-bottom: 40px;
          }
          @include MQ(XL) {
            margin-bottom: 40px;
          }
        }
      }
      .member-card-container {
        .member-card {
          display: inline-block;
          border-radius: 10px;
          width: 100%;
          position: relative;
          z-index: 1;
          .member-card-overlay {
            position: absolute;
            background: #F2F2F2;
            opacity: 0.95;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #707070;
            z-index: 1;
            color: $primary-text;
            border-radius: 10px;
            padding: 15px 40px 15px 15px;
            text-align: left;
            font-weight: 600;
            font-style: italic;
            overflow-y: visible;
            overflow-x: hidden;
            a {
              text-decoration: none;
            }
            .member-card-overlay-hint {
              font-size: 12px;
            }
            .member-card-overlay-close {
              position: absolute;
              right: 5px;
              top: 5px;
              color: $primary-text;
              svg {
                height: 35px;
                width: 35px;
              }
            }
            .member-card-overlay-highlight {
              color: $primary;
            }
          }
          &.member-card-gray {
            .v-card {
              background-color: $basic-gray !important;
            }
          }
          &.member-card-distance {
            margin-top: 20px;
          }
          .member-title, p {
            text-align: left;
            font-style: italic;
          }
          p {
            font-weight: 600;
            a {
              text-decoration: none;
            }
          }
          &.member-card-top-offset {
            margin-top: 100px;
            @include MQ(S) {
              margin-top: 20px;
            }
            @include MQ(XS) {
              margin-top: 20px;
            }
          }
          &.member-card-top-small-offset {
            margin-top: 50px;
            @include MQ(XS) {
              margin-top: 20px;
            }
          }
          .v-card {
            border-radius: 8px;
            overflow: hidden;
            h1, p {
              padding-left: 10px;
              padding-right: 10px;
            }
            h1 {
              padding-top: 10px;
            }
            .v-image {
              height: 355px !important;
              @include MQ(XS) {
                min-height: 255px;
              }
            }
          }
          .member-card-action {
            margin-top: 80px;
            padding: 10px;
            div {
              font-weight: 600;
              font-style: italic;
              svg {
                height: 14px;
                margin-left: 5px;
                margin-bottom: -2px;
              }
            }
          }
          .member-card-action-bottom {
            position: absolute;
            bottom: 0;
          }
          .member-card-action-white {
            color: white;
            svg {
              path {
                fill: white !important;
              }
            }
          }
        }
        .member-picture-separator {
          height: 550px;
          position: relative;
          margin-top: -100px;
          margin-bottom: -175px;
          .v-image {
            height: 550px !important;
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
          }
          @include MQ(L) {
            height: 425px !important;
            margin-bottom: -150px;
            .v-image {
              height: 425px !important;
            }
          }
          @include MQ(M) {
            height: 355px !important;
            margin-top: -85px;
            margin-bottom: -100px;
            .v-image {
              height: 355px !important;
            }
          }
          @include MQ(S) {
            display: none;
          }
          @include MQ(XS) {
            display: none;
          }
        }
      }
      .v-tabs {
        .v-tabs-bar {
          background-color: $light-gray !important;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          .v-slide-group__prev {
            display: none !important;
          }
          .v-slide-group__wrapper {
            .v-tabs-bar__content {
              .v-tab {
                letter-spacing: 0.0392857143em;
                text-transform: none;
                font-size: 22px;
                color: $primary-text;
                min-width: 80px;
                @include MQ(M) {
                  font-size: 18px;
                }
                @include MQ(S) {
                  font-size: 16px;
                }
                @include MQ(XS) {
                  font-size: 14px;
                }
                &.v-tab--active {
                  color: $primary;
                }
                .v-icon {
                  margin-bottom: -3px;
                }
              }
              .v-tabs-slider-wrapper {
                height: 3px !important;
              }
            }
          }
        }
      }
      .v-tabs-items {
        .v-window-item {
          .v-main {
            h1, h2 {
              @include MQ(XS) {
                font-size: 22px;
              }
            }
            h2 {
              font-weight: 400;
              @include MQ(XS) {
                font-size: 18px;
              }
            }
            p {
              .v-icon {
                margin-top: -3px;
              }
            }
            .highlight {
              color: $primary;
            }
            .short-p {
              max-width: 800px;
              display: inline-block;
            }
            .v-card {
              .v-card__text {
                color: $primary-text;
                .t-shirt-image {
                  max-width: 750px;
                  width: 100%;
                }

                .coupon-container, .hoodie-container {
                  background-image: url(https://ebikeworldfederation.com/images/member/EBWF_MITGLIED_Filterbild_01.png);
                  height: 400px;
                  background-position: center;
                  background-size: cover;
                  position: relative;
                  @include MQ(XS) {
                    height: 200px;
                  }
                  .icons-container {
                    position: absolute;
                    top: 50%;
                    left: 20px;
                    width: 100%;
                    transform: translateY(-50%);
                    @include MQ(XS) {
                      left: 10px;
                      transform: translateY(-50%);
                    }
                    .percent {
                      height: 80px;
                      margin-bottom: 5px;
                      margin-right: 20px;
                      @include MQ(XS) {
                        margin-right: 10px;
                        height: 40px;
                      }
                    }
                    .logo {
                      height: 100px;
                      @include MQ(XS) {
                        height: 55px;
                      }
                    }
                  }
                }
                .hoodie-container {
                  background-image: url(https://ebikeworldfederation.com/images/infobar/new/EBWF_Mitgliedschaft_Contentbild_Hoodies.jpg);
                }
                .print-abo-container {
                  img {
                    height: 400px;
                  }
                }
              }
            }
            .v-expansion-panel {
              .v-expansion-panel-header {
                padding-left: 5px;
                padding-right: 5px;
              }
              .v-expansion-panel-content {
                .v-expansion-panel-content__wrap {
                  padding-left: 5px;
                  padding-right: 5px;
                  text-align: left;
                }
              }
            }
          }
        }
      }
      .v-btn {
        margin-left: 0;
        margin-right: 15px;
        text-transform: none;
        border-radius: 10px;
        margin-bottom: 10px;
        @include MQ(XS) {
          margin-right: 10px;
        }
        &.selected {
          background-color: $primary !important;
          color: white;
        }
        &.error {
          background-color: $basic-red !important;
          color: white;
        }
        .v-btn__content {
          font-size: 18px;
        }
      }
      .v-input {
        margin-bottom: 10px;
        .v-label {
          font-size: 20px;
          height: 22px;
          color: $primary-text;
        }
        input {
          font-size: 18px;
          max-height: 40px;
        }
      }

      .v-select {
        &.error--text {
          .v-select__slot {
            .v-label {
              color: #F53A33;
            }
          }
        }
      }

      .misc {
        position: absolute;
        opacity: 0;
      }
    }
    .member-bottom-register-container {
      background-color: $primary; // #027AB8
      color: white;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      text-align: left;
      .v-icon {
        margin-left: 10px;
        margin-top: -20px;
        @include MQ(S) {
          margin-left: 5px;
        }
      }
      .member-bottom-register-price {
        font-size: 22px;
        @include MQ(XS) {
          font-size: 16px;
          padding-top: 20px;
        }
      }
      .member-bottom-register-text {
        margin-top: 7px;
        @include MQ(S) {
          font-size: 14px;
        }
        @include MQ(XS) {
          font-size: 14px;
          padding-bottom: 0;
          padding-top: 0;
        }
      }
      .v-btn {
        text-transform: none;
        background-color: white;
        font-weight: 600;
        font-style: italic;
        border-radius: 16px;
        font-size: 20px !important;
        svg {
          width: 35px;
          height: 20px;
          margin-left: 10px;
        }
        @include MQ(L) {
          font-size: 18px !important;
          svg {
            width: 25px;
          }
        }
        @include MQ(M) {
          font-size: 16px !important;
          svg {
            width: 20px;
          }
        }
        @include MQ(S) {
          font-size: 16px !important;
          svg {
            width: 20px;
          }
        }
        @include MQ(XS) {
          font-size: 16px !important;
          svg {
            width: 20px;
          }
        }
      }
    }
    .faq-container {
      margin-top: -100px;
      margin-bottom: 50px;
      position: relative;
      background-color: $basic-gray;
      border-radius: 10px;
      padding-bottom: 30px;
      padding-top: 20px;
      @include MQ(XS) {
        padding-left: 15px;
        padding-right: 15px;
      }
      @include MQ(S) {
        padding-left: 15px;
        padding-right: 15px;
      }
      &.faq-container-offset {
        margin-top: 50px;
      }
      .v-expansion-panel {
        margin-top: 16px;
        border-radius: 10px !important;
        font-style: italic;
        .v-expansion-panel-content {
          font-style: normal;
          a {
            text-decoration: none;
          }
        }
        &::after {
          border: none;
        }
      }
      .v-btn {
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        background-color: white;
        color: $primary-text;
        font-style: italic;
        font-weight: 600;
        height: 45px;
        width: 100%;
        justify-content: left;
        margin-bottom: 0;
        .v-btn__content {
          text-align: left;
        }
        &:hover {
          color: $primary;
          &::before{
            opacity: 0;
          }
        }
        .v-icon {
          height: 25px;
          width: 25px;
          svg {
            margin-top: -2px;
            height: 25px;
            width: 25px;
          }
        }
      }
    }
  }
</style>
