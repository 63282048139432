export default {
  data: () => ({
    isoCountriesEN: [
      { value: 'AF', label: 'Afghanistan' },
      { value: 'AX', label: 'Aland Islands' },
      { value: 'AL', label: 'Albania' },
      { value: 'DZ', label: 'Algeria' },
      { value: 'AS', label: 'American Samoa' },
      { value: 'AD', label: 'Andorra' },
      { value: 'AO', label: 'Angola' },
      { value: 'AI', label: 'Anguilla' },
      { value: 'AQ', label: 'Antarctica' },
      { value: 'AG', label: 'Antigua And Barbuda' },
      { value: 'AR', label: 'Argentina' },
      { value: 'AM', label: 'Armenia' },
      { value: 'AW', label: 'Aruba' },
      { value: 'AU', label: 'Australia' },
      { value: 'AT', label: 'Austria' },
      { value: 'AZ', label: 'Azerbaijan' },
      { value: 'BS', label: 'Bahamas' },
      { value: 'BH', label: 'Bahrain' },
      { value: 'BD', label: 'Bangladesh' },
      { value: 'BB', label: 'Barbados' },
      { value: 'BY', label: 'Belarus' },
      { value: 'BE', label: 'Belgium' },
      { value: 'BZ', label: 'Belize' },
      { value: 'BJ', label: 'Benin' },
      { value: 'BM', label: 'Bermuda' },
      { value: 'BT', label: 'Bhutan' },
      { value: 'BO', label: 'Bolivia' },
      { value: 'BA', label: 'Bosnia And Herzegovina' },
      { value: 'BW', label: 'Botswana' },
      { value: 'BV', label: 'Bouvet Island' },
      { value: 'BR', label: 'Brazil' },
      { value: 'IO', label: 'British Indian Ocean Territory' },
      { value: 'BN', label: 'Brunei Darussalam' },
      { value: 'BG', label: 'Bulgaria' },
      { value: 'BF', label: 'Burkina Faso' },
      { value: 'BI', label: 'Burundi' },
      { value: 'KH', label: 'Cambodia' },
      { value: 'CM', label: 'Cameroon' },
      { value: 'CA', label: 'Canada' },
      { value: 'CV', label: 'Cape Verde' },
      { value: 'KY', label: 'Cayman Islands' },
      { value: 'CF', label: 'Central African Republic' },
      { value: 'TD', label: 'Chad' },
      { value: 'CL', label: 'Chile' },
      { value: 'CN', label: 'China' },
      { value: 'CX', label: 'Christmas Island' },
      { value: 'CC', label: 'Cocos (Keeling) Islands' },
      { value: 'CO', label: 'Colombia' },
      { value: 'KM', label: 'Comoros' },
      { value: 'CG', label: 'Congo' },
      { value: 'CD', label: 'Congo, Democratic Republic' },
      { value: 'CK', label: 'Cook Islands' },
      { value: 'CR', label: 'Costa Rica' },
      { value: 'CI', label: 'Cote D\'Ivoire' },
      { value: 'HR', label: 'Croatia' },
      { value: 'CU', label: 'Cuba' },
      { value: 'CY', label: 'Cyprus' },
      { value: 'CZ', label: 'Czech Republic' },
      { value: 'DK', label: 'Denmark' },
      { value: 'DJ', label: 'Djibouti' },
      { value: 'DM', label: 'Dominica' },
      { value: 'DO', label: 'Dominican Republic' },
      { value: 'EC', label: 'Ecuador' },
      { value: 'EG', label: 'Egypt' },
      { value: 'SV', label: 'El Salvador' },
      { value: 'GQ', label: 'Equatorial Guinea' },
      { value: 'ER', label: 'Eritrea' },
      { value: 'EE', label: 'Estonia' },
      { value: 'ET', label: 'Ethiopia' },
      { value: 'FK', label: 'Falkland Islands (Malvinas)' },
      { value: 'FO', label: 'Faroe Islands' },
      { value: 'FJ', label: 'Fiji' },
      { value: 'FI', label: 'Finland' },
      { value: 'FR', label: 'France' },
      { value: 'GF', label: 'French Guiana' },
      { value: 'PF', label: 'French Polynesia' },
      { value: 'TF', label: 'French Southern Territories' },
      { value: 'GA', label: 'Gabon' },
      { value: 'GM', label: 'Gambia' },
      { value: 'GE', label: 'Georgia' },
      { value: 'DE', label: 'Germany' },
      { value: 'GH', label: 'Ghana' },
      { value: 'GI', label: 'Gibraltar' },
      { value: 'GR', label: 'Greece' },
      { value: 'GL', label: 'Greenland' },
      { value: 'GD', label: 'Grenada' },
      { value: 'GP', label: 'Guadeloupe' },
      { value: 'GU', label: 'Guam' },
      { value: 'GT', label: 'Guatemala' },
      { value: 'GG', label: 'Guernsey' },
      { value: 'GN', label: 'Guinea' },
      { value: 'GW', label: 'Guinea-Bissau' },
      { value: 'GY', label: 'Guyana' },
      { value: 'HT', label: 'Haiti' },
      { value: 'HM', label: 'Heard Island & Mcdonald Islands' },
      { value: 'VA', label: 'Holy See (Vatican City State)' },
      { value: 'HN', label: 'Honduras' },
      { value: 'HK', label: 'Hong Kong' },
      { value: 'HU', label: 'Hungary' },
      { value: 'IS', label: 'Iceland' },
      { value: 'IN', label: 'India' },
      { value: 'ID', label: 'Indonesia' },
      { value: 'IR', label: 'Iran, Islamic Republic Of' },
      { value: 'IQ', label: 'Iraq' },
      { value: 'IE', label: 'Ireland' },
      { value: 'IM', label: 'Isle Of Man' },
      { value: 'IL', label: 'Israel' },
      { value: 'IT', label: 'Italy' },
      { value: 'JM', label: 'Jamaica' },
      { value: 'JP', label: 'Japan' },
      { value: 'JE', label: 'Jersey' },
      { value: 'JO', label: 'Jordan' },
      { value: 'KZ', label: 'Kazakhstan' },
      { value: 'KE', label: 'Kenya' },
      { value: 'KI', label: 'Kiribati' },
      { value: 'KR', label: 'Korea' },
      { value: 'KW', label: 'Kuwait' },
      { value: 'KG', label: 'Kyrgyzstan' },
      { value: 'LA', label: 'Lao People\'s Democratic Republic' },
      { value: 'LV', label: 'Latvia' },
      { value: 'LB', label: 'Lebanon' },
      { value: 'LS', label: 'Lesotho' },
      { value: 'LR', label: 'Liberia' },
      { value: 'LY', label: 'Libyan Arab Jamahiriya' },
      { value: 'LI', label: 'Liechtenstein' },
      { value: 'LT', label: 'Lithuania' },
      { value: 'LU', label: 'Luxembourg' },
      { value: 'MO', label: 'Macao' },
      { value: 'MK', label: 'Macedonia' },
      { value: 'MG', label: 'Madagascar' },
      { value: 'MW', label: 'Malawi' },
      { value: 'MY', label: 'Malaysia' },
      { value: 'MV', label: 'Maldives' },
      { value: 'ML', label: 'Mali' },
      { value: 'MT', label: 'Malta' },
      { value: 'MH', label: 'Marshall Islands' },
      { value: 'MQ', label: 'Martinique' },
      { value: 'MR', label: 'Mauritania' },
      { value: 'MU', label: 'Mauritius' },
      { value: 'YT', label: 'Mayotte' },
      { value: 'MX', label: 'Mexico' },
      { value: 'FM', label: 'Micronesia, Federated States Of' },
      { value: 'MD', label: 'Moldova' },
      { value: 'MC', label: 'Monaco' },
      { value: 'MN', label: 'Mongolia' },
      { value: 'ME', label: 'Montenegro' },
      { value: 'MS', label: 'Montserrat' },
      { value: 'MA', label: 'Morocco' },
      { value: 'MZ', label: 'Mozambique' },
      { value: 'MM', label: 'Myanmar' },
      { value: 'NA', label: 'Namibia' },
      { value: 'NR', label: 'Nauru' },
      { value: 'NP', label: 'Nepal' },
      { value: 'NL', label: 'Netherlands' },
      { value: 'AN', label: 'Netherlands Antilles' },
      { value: 'NC', label: 'New Caledonia' },
      { value: 'NZ', label: 'New Zealand' },
      { value: 'NI', label: 'Nicaragua' },
      { value: 'NE', label: 'Niger' },
      { value: 'NG', label: 'Nigeria' },
      { value: 'NU', label: 'Niue' },
      { value: 'NF', label: 'Norfolk Island' },
      { value: 'MP', label: 'Northern Mariana Islands' },
      { value: 'NO', label: 'Norway' },
      { value: 'OM', label: 'Oman' },
      { value: 'PK', label: 'Pakistan' },
      { value: 'PW', label: 'Palau' },
      { value: 'PS', label: 'Palestinian Territory, Occupied' },
      { value: 'PA', label: 'Panama' },
      { value: 'PG', label: 'Papua New Guinea' },
      { value: 'PY', label: 'Paraguay' },
      { value: 'PE', label: 'Peru' },
      { value: 'PH', label: 'Philippines' },
      { value: 'PN', label: 'Pitcairn' },
      { value: 'PL', label: 'Poland' },
      { value: 'PT', label: 'Portugal' },
      { value: 'PR', label: 'Puerto Rico' },
      { value: 'QA', label: 'Qatar' },
      { value: 'RE', label: 'Reunion' },
      { value: 'RO', label: 'Romania' },
      { value: 'RU', label: 'Russian Federation' },
      { value: 'RW', label: 'Rwanda' },
      { value: 'BL', label: 'Saint Barthelemy' },
      { value: 'SH', label: 'Saint Helena' },
      { value: 'KN', label: 'Saint Kitts And Nevis' },
      { value: 'LC', label: 'Saint Lucia' },
      { value: 'MF', label: 'Saint Martin' },
      { value: 'PM', label: 'Saint Pierre And Miquelon' },
      { value: 'VC', label: 'Saint Vincent And Grenadines' },
      { value: 'WS', label: 'Samoa' },
      { value: 'SM', label: 'San Marino' },
      { value: 'ST', label: 'Sao Tome And Principe' },
      { value: 'SA', label: 'Saudi Arabia' },
      { value: 'SN', label: 'Senegal' },
      { value: 'RS', label: 'Serbia' },
      { value: 'SC', label: 'Seychelles' },
      { value: 'SL', label: 'Sierra Leone' },
      { value: 'SG', label: 'Singapore' },
      { value: 'SK', label: 'Slovakia' },
      { value: 'SI', label: 'Slovenia' },
      { value: 'SB', label: 'Solomon Islands' },
      { value: 'SO', label: 'Somalia' },
      { value: 'ZA', label: 'South Africa' },
      { value: 'GS', label: 'South Georgia And Sandwich Isl.' },
      { value: 'ES', label: 'Spain' },
      { value: 'LK', label: 'Sri Lanka' },
      { value: 'SD', label: 'Sudan' },
      { value: 'SR', label: 'Suriname' },
      { value: 'SJ', label: 'Svalbard And Jan Mayen' },
      { value: 'SZ', label: 'Swaziland' },
      { value: 'SE', label: 'Sweden' },
      { value: 'CH', label: 'Switzerland' },
      { value: 'SY', label: 'Syrian Arab Republic' },
      { value: 'TW', label: 'Taiwan' },
      { value: 'TJ', label: 'Tajikistan' },
      { value: 'TZ', label: 'Tanzania' },
      { value: 'TH', label: 'Thailand' },
      { value: 'TL', label: 'Timor-Leste' },
      { value: 'TG', label: 'Togo' },
      { value: 'TK', label: 'Tokelau' },
      { value: 'TO', label: 'Tonga' },
      { value: 'TT', label: 'Trinidad And Tobago' },
      { value: 'TN', label: 'Tunisia' },
      { value: 'TR', label: 'Turkey' },
      { value: 'TM', label: 'Turkmenistan' },
      { value: 'TC', label: 'Turks And Caicos Islands' },
      { value: 'TV', label: 'Tuvalu' },
      { value: 'UG', label: 'Uganda' },
      { value: 'UA', label: 'Ukraine' },
      { value: 'AE', label: 'United Arab Emirates' },
      { value: 'GB', label: 'United Kingdom' },
      { value: 'US', label: 'United States' },
      { value: 'UM', label: 'United States Outlying Islands' },
      { value: 'UY', label: 'Uruguay' },
      { value: 'UZ', label: 'Uzbekistan' },
      { value: 'VU', label: 'Vanuatu' },
      { value: 'VE', label: 'Venezuela' },
      { value: 'VN', label: 'Viet Nam' },
      { value: 'VG', label: 'Virgin Islands, British' },
      { value: 'VI', label: 'Virgin Islands, U.S.' },
      { value: 'WF', label: 'Wallis And Futuna' },
      { value: 'EH', label: 'Western Sahara' },
      { value: 'YE', label: 'Yemen' },
      { value: 'ZM', label: 'Zambia' },
      { value: 'ZW', label: 'Zimbabwe' }
    ],
    isoCountriesDE: [
      { label: 'Afghanistan', value: 'AF' },
      { label: 'Ägypten', value: 'EG' },
      { label: 'Albanien', value: 'AL' },
      { label: 'Algerien', value: 'DZ' },
      { label: 'Andorra', value: 'AD' },
      { label: 'Angola', value: 'AO' },
      { label: 'Antigua und Barbuda', value: 'AG' },
      { label: 'Äquatorialguinea', value: 'GQ' },
      { label: 'Argentinien', value: 'AR' },
      { label: 'Armenien', value: 'AM' },
      { label: 'Aserbaidschan', value: 'AZ' },
      { label: 'Äthiopien', value: 'ET' },
      { label: 'Australien', value: 'AU' },
      { label: 'Bahamas', value: 'BS' },
      { label: 'Bahrain', value: 'BH' },
      { label: 'Bangladesch', value: 'BD' },
      { label: 'Barbados', value: 'BB' },
      { label: 'Belarus', value: 'BY' },
      { label: 'Belgien', value: 'BE' },
      { label: 'Belize', value: 'BZ' },
      { label: 'Benin', value: 'BJ' },
      { label: 'Bhutan', value: 'BT' },
      { label: 'Bolivien', value: 'BO' },
      { label: 'Bosnien und Herzegowina', value: 'BA' },
      { label: 'Botswana', value: 'BW' },
      { label: 'Brasilien', value: 'BR' },
      { label: 'Brunei', value: 'BN' },
      { label: 'Bulgarien', value: 'BG' },
      { label: 'Burkina Faso', value: 'BF' },
      { label: 'Burundi', value: 'BI' },
      { label: 'Chile', value: 'CL' },
      { label: 'Volksrepublik China', value: 'CN' },
      { label: 'Costa Rica', value: 'CR' },
      { label: 'Dänemark', value: 'DK' },
      { label: 'Deutschland', value: 'DE' },
      { label: 'Dominica', value: 'DM' },
      { label: 'Dominikanische Republik', value: 'DO' },
      { label: 'Dschibuti', value: 'DJ' },
      { label: 'Ecuador', value: 'EC' },
      { label: 'Elfenbeinküste', value: 'CI' },
      { label: 'El Salvador', value: 'SV' },
      { label: 'Eritrea', value: 'ER' },
      { label: 'Estland', value: 'EE' },
      { label: 'Eswatini', value: 'SZ' },
      { label: 'Fidschi', value: 'FJ' },
      { label: 'Finnland', value: 'FI' },
      { label: 'Frankreich', value: 'FR' },
      { label: 'Gabun', value: 'GA' },
      { label: 'Gambia', value: 'GM' },
      { label: 'Georgien', value: 'GE' },
      { label: 'Ghana', value: 'GH' },
      { label: 'Grenada', value: 'GD' },
      { label: 'Griechenland', value: 'GR' },
      { label: 'Guatemala', value: 'GT' },
      { label: 'Guinea', value: 'GN' },
      { label: 'Guinea-Bissau', value: 'GW' },
      { label: 'Guyana', value: 'GY' },
      { label: 'Haiti', value: 'HT' },
      { label: 'Honduras', value: 'HN' },
      { label: 'Indien', value: 'IN' },
      { label: 'Indonesien', value: 'ID' },
      { label: 'Irak', value: 'IQ' },
      { label: 'Iran', value: 'IR' },
      { label: 'Irland', value: 'IE' },
      { label: 'Island', value: 'IS' },
      { label: 'Israel', value: 'IL' },
      { label: 'Italien', value: 'IT' },
      { label: 'Jamaika', value: 'JM' },
      { label: 'Japan', value: 'JP' },
      { label: 'Jemen', value: 'YE' },
      { label: 'Jordanien', value: 'JO' },
      { label: 'Kambodscha', value: 'KH' },
      { label: 'Kamerun', value: 'CM' },
      { label: 'Kanada', value: 'CA' },
      { label: 'Kap Verde', value: 'CV' },
      { label: 'Kasachstan', value: 'KZ' },
      { label: 'Katar', value: 'QA' },
      { label: 'Kenia', value: 'KE' },
      { label: 'Kirgisistan', value: 'KG' },
      { label: 'Kiribati', value: 'KI' },
      { label: 'Kolumbien', value: 'CO' },
      { label: 'Komoren', value: 'KM' },
      { label: 'Kongo, Demokratische Republik', value: 'CD' },
      { label: 'Kongo, Republik', value: 'CG' },
      { label: 'Korea, Nord', value: 'KP' },
      { label: 'Korea, Süd', value: 'KR' },
      { label: 'Kroatien', value: 'HR' },
      { label: 'Kuba', value: 'CU' },
      { label: 'Kuwait', value: 'KW' },
      { label: 'Laos', value: 'LA' },
      { label: 'Lesotho', value: 'LS' },
      { label: 'Lettland', value: 'LV' },
      { label: 'Libanon', value: 'LB' },
      { label: 'Liberia', value: 'LR' },
      { label: 'Libyen', value: 'LY' },
      { label: 'Liechtenstein', value: 'LI' },
      { label: 'Litauen', value: 'LT' },
      { label: 'Luxemburg', value: 'LU' },
      { label: 'Madagaskar', value: 'MG' },
      { label: 'Malawi', value: 'MW' },
      { label: 'Malaysia', value: 'MY' },
      { label: 'Malediven', value: 'MV' },
      { label: 'Mali', value: 'ML' },
      { label: 'Malta', value: 'MT' },
      { label: 'Marokko', value: 'MA' },
      { label: 'Marshallinseln', value: 'MH' },
      { label: 'Mauretanien', value: 'MR' },
      { label: 'Mauritius', value: 'MU' },
      { label: 'Mexiko', value: 'MX' },
      { label: 'Mikronesien', value: 'FM' },
      { label: 'Moldau', value: 'MD' },
      { label: 'Monaco', value: 'MC' },
      { label: 'Mongolei', value: 'MN' },
      { label: 'Montenegro', value: 'ME' },
      { label: 'Mosambik', value: 'MZ' },
      { label: 'Myanmar', value: 'MM' },
      { label: 'Namibia', value: 'NA' },
      { label: 'Nauru', value: 'NR' },
      { label: 'Nepal', value: 'NP' },
      { label: 'Neuseeland', value: 'NZ' },
      { label: 'Nicaragua', value: 'NI' },
      { label: 'Niederlande', value: 'NL' },
      { label: 'Niger', value: 'NE' },
      { label: 'Nigeria', value: 'NG' },
      { label: 'Nordmazedonien', value: 'MK' },
      { label: 'Norwegen', value: 'NO' },
      { label: 'Oman', value: 'OM' },
      { label: 'Österreich', value: 'AT' },
      { label: 'Osttimor', value: 'TL' },
      { label: 'Pakistan', value: 'PK' },
      { label: 'Palau', value: 'PW' },
      { label: 'Panama', value: 'PA' },
      { label: 'Papua-Neuguinea', value: 'PG' },
      { label: 'Paraguay', value: 'PY' },
      { label: 'Peru', value: 'PE' },
      { label: 'Philippinen', value: 'PH' },
      { label: 'Polen', value: 'PL' },
      { label: 'Portugal', value: 'PT' },
      { label: 'Ruanda', value: 'RW' },
      { label: 'Rumänien', value: 'RO' },
      { label: 'Russland', value: 'RU' },
      { label: 'Salomonen', value: 'SB' },
      { label: 'Sambia', value: 'ZM' },
      { label: 'Samoa', value: 'WS' },
      { label: 'San Marino', value: 'SM' },
      { label: 'São Tomé und Príncipe', value: 'ST' },
      { label: 'Saudi-Arabien', value: 'SA' },
      { label: 'Schweden', value: 'SE' },
      { label: 'Schweiz', value: 'CH' },
      { label: 'Senegal', value: 'SN' },
      { label: 'Serbien', value: 'RS' },
      { label: 'Seychellen', value: 'SC' },
      { label: 'Sierra Leone', value: 'SL' },
      { label: 'Simbabwe', value: 'ZW' },
      { label: 'Singapur', value: 'SG' },
      { label: 'Slowakei', value: 'SK' },
      { label: 'Slowenien', value: 'SI' },
      { label: 'Somalia', value: 'SO' },
      { label: 'Spanien', value: 'ES' },
      { label: 'Sri Lanka', value: 'LK' },
      { label: 'St. Kitts und Nevis', value: 'KN' },
      { label: 'St. Lucia', value: 'LC' },
      { label: 'St. Vincent und die Grenadinen', value: 'VC' },
      { label: 'Südafrika', value: 'ZA' },
      { label: 'Sudan', value: 'SD' },
      { label: 'Südsudan', value: 'SS' },
      { label: 'Suriname', value: 'SR' },
      { label: 'Syrien', value: 'SY' },
      { label: 'Tadschikistan', value: 'TJ' },
      { label: 'Tansania', value: 'TZ' },
      { label: 'Thailand', value: 'TH' },
      { label: 'Togo', value: 'TG' },
      { label: 'Tonga', value: 'TO' },
      { label: 'Trinidad und Tobago', value: 'TT' },
      { label: 'Tschad', value: 'TD' },
      { label: 'Tschechien', value: 'CZ' },
      { label: 'Tunesien', value: 'TN' },
      { label: 'Türkei', value: 'TR' },
      { label: 'Turkmenistan', value: 'TM' },
      { label: 'Tuvalu', value: 'TV' },
      { label: 'Uganda', value: 'UG' },
      { label: 'Ukraine', value: 'UA' },
      { label: 'Ungarn', value: 'HU' },
      { label: 'Uruguay', value: 'UY' },
      { label: 'Usbekistan', value: 'UZ' },
      { label: 'Vanuatu', value: 'VU' },
      { label: 'Venezuela', value: 'VE' },
      { label: 'Vereinigte Arabische Emirate', value: 'AE' },
      { label: 'Vereinigte Staaten', value: 'US' },
      { label: 'Vereinigtes Königreich', value: 'GB' },
      { label: 'Vietnam', value: 'VN' },
      { label: 'Zentralafrikanische Republik', value: 'CF' },
      { label: 'Zypern', value: 'CY' }]
  }),
  methods: {
    getIsoCountries () {
      if (this.$i18n.locale.toUpperCase() === 'EN') {
        return this.isoCountriesEN
      }
      return this.isoCountriesDE
    },
    getCountryNameByIsoCode (isoCode) {
      if (this.$i18n.locale === 'en') {
        for (let i = 0; i < this.isoCountriesEN.length; i += 1) {
          if (this.isoCountriesEN[i].value === isoCode) {
            return this.isoCountriesEN[i].label
          }
        }
      }
      for (let i = 0; i < this.isoCountriesDE.length; i += 1) {
        if (this.isoCountriesDE[i].value === isoCode) {
          return this.isoCountriesDE[i].label
        }
      }
    }
  }
}
