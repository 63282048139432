<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="faq-container">
          <v-row class="align-center justify-center">
            <v-col cols="12" sm="12" md="11" lg="11" xl="10">
              <v-row class="text-left">
                <v-col cols="12" class="pb-0">
                  <h1 class="member-title">FAQ:</h1>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>
                          Wer kann die Mitgliedschaft erwerben?
                        </strong>
                        <template v-slot:actions>
                          <v-icon color="primary">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Jede/r kann die Mitgliedschaft im E-Bike Weltverband erwerben. <br> <br>
                        Bitte beachte jedoch, dass die Inanspruchnahme der Ermäßigung auf E-Bike Vollkasko Versicherung
                        von hepster nur Personen mit Wohnsitz in Österreich und Deutschland derzeit möglich ist. Leider
                        ist hierzu keine entsprechende Preisminderung des Mitgliedschaftspreises möglich.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>
                          Wann startet und endet die Mitgliedschaft?
                        </strong>
                        <template v-slot:actions>
                          <v-icon color="primary">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Die Mitgliedschaft startet am Tag der Anmeldung und ab diesem Zeitpunkt läuft die Mitgliedschaft
                        ein (1) Jahr. Die Mitgliedschaft wird auf unbestimmte Dauer abgeschlossen, verlängert sich von
                        Jahr zu Jahr automatisch mit einer Kündigungsfrist von 3 Werktagen zum Ablauf der
                        Mitgliedschaftsperiode. <br>
                        Du und wir können den Vertrag unter bestimmten Voraussetzungen vorzeitig kündigen.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>
                          Wie ist der genaue Ablauf?
                        </strong>
                        <template v-slot:actions>
                          <v-icon color="primary">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Bei Bestellung und Bezahlung der Mitgliedschaft an die E-Bike World Federation erhältst du
                        innerhalb von 3 Werktagen Informationen zu den etwaigen Leistungen.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>
                          Wer ist mein Ansprechpartner?
                        </strong>
                        <template v-slot:actions>
                          <v-icon color="primary">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Wir stehen für dich als Ansprechpartner via E-Mail
                        (<a href="mailto:info@ebikeworldfederation.com">
                        info@ebikeworldfederation.com
                      </a>) oder Telefon (<a href="tel:+43 4842 20607">+43 4842 20607</a>)
                        jederzeit zur Verfügung. <br><br>
                        Ansprechpartner bezüglich Leistungen Dritter ist der jeweilige Leistungserbringer mit deren
                        Folgeleistungen/ -aufgaben: <br><br>
                        <strong>
                          Ermäßigung auf E-Bike Schutz Versicherung gegen Beschädigung, Verschleiß & Diebstahl
                        </strong> <br>
                        Ansprechpartner │ Informationen │ Dokumente │ Schadensmeldung <br>
                        Versicherungsunternehmen MOINsure GmbH (unter der Marke hepster) hier.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>
                          Gibt es Rabatte für Kinder, Familienmitglieder oder Vereine?
                        </strong>
                        <template v-slot:actions>
                          <v-icon color="primary">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Zurzeit sind keine Rabatte ǀ Ermäßigungen möglich.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>
                          Wie kommt es zu diesem unschlagbaren Mitgliedschaftsangebot?
                        </strong>
                        <template v-slot:actions>
                          <v-icon color="primary">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Durch die Mithilfe unserer Partner ist es uns als gemeinnütziger E-Bike Weltverband möglich
                        diesen Mitgliedspreis anzubieten.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <strong>
                          Wie komme ich in den Genuss der prozentuellen Ermäßigungen?
                        </strong>
                        <template v-slot:actions>
                          <v-icon color="primary">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <strong>20 % Ermäßigung auf alle Produkte des E-Bike Weltverbandes
                          <a href="https://wirsindebike.at/" target="_blank">www.wirsindebike.at</a></strong>
                        <br>
                        Als Mitglied bieten wir Dir 20 % Ermäßigung auf alle Produkte im offiziellen Shop
                        <a href="https://wirsindebike.at/" target="_blank">www.wirsindebike.at</a> des E-Bike
                        Weltverbandes. Nach Anmeldung zur Mitgliedschaft erhältst Du eine einen entsprechenden
                        Ermäßigungscode hierfür. <br><br>
                        <strong>
                          20 % Ermäßigung auf E-Bike Schutz Versicherung gegen Beschädigung, Verschleiß und Diebstahl
                        </strong> <br>
                        Mitglieder des E-Bike Weltverbandes erhalten 20 % Ermäßigung beim Abschluss einer E-Bike
                        Versicherung unseres Partners MOINsure GmbH (unter der Marke hepster). <br><br>
                        Beim Abschluss der Versicherung einfach den Gutscheincode eingeben, den Du per Mail nach der
                        Anmeldung zur Mitgliedschaft im E-Bike Weltverband bekommen hast (falls nicht vorhanden, bitte
                        kurz eine Mail an
                        <a href="mailto:info@ebikeworldfederation.com">info@ebikeworldfederation.com</a> schreiben).
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        <h1 class="member-title">HAST DU NOCH <span class="member-title-primary">FRAGEN?</span></h1>
      </v-col>
      <v-col cols="12">
        <v-card class="faq-container faq-container-offset">
          <v-row class="align-center justify-center">
            <v-col cols="12" sm="12" md="11" lg="11" xl="10">
              <v-row class="text-left">
                <v-col cols="12" class="pb-0">
                  <h1 class="member-title">{{$t('support.hereForYou')}}</h1>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn href="tel:+43484220607">
                    <v-icon left color="primary">
                      {{icons.phone}}
                    </v-icon>
                    +43 4842 20607
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn href="mailto:info@ebikeworldfederation.com">
                    <v-icon left color="primary">
                      {{icons.mail}}
                    </v-icon>
                    info@ebikeworldfederation.com
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mdiEmail, mdiPhone} from '@mdi/js';
  export default {
    data () {
      return {
        icons: {
          mail: mdiEmail,
          phone: mdiPhone,
        },
      }
    },
    methods: {
    }
  }
</script>

