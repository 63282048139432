<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="member-overview-container">
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="12" md="10" offset-md="1">
                <h1 class="member-title member-title-distance">
                  Deine Mitgliedschaft auf einen Blick:
                </h1>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row class="text-left">
              <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                <v-row>
                  <v-col cols="12" sm="12" md="10" offset-md="2">
                    <table class="mb-1">
                      <tr>
                        <td><v-icon class="highlight">{{icons.check}}</v-icon></td>
                        <td>
                          Gesicherter Startplatz bei allen Events des E-Bike Weltverbandes
                        </td>
                      </tr>
                    </table>
                    <table class="mb-1">
                      <tr>
                        <td><v-icon class="highlight">{{icons.check}}</v-icon></td>
                        <td>
                          1x Wir sind E-Bike – Multifunktionstuch + EBWF-Edition - Mudguard im Wert von Euro 39,00
                        </td>
                      </tr>
                    </table>
                    <table class="mb-1">
                      <tr>
                        <td><v-icon class="highlight">{{icons.check}}</v-icon></td>
                        <td>
                          20 % Exklusive Ermäßigung auf E-Bike Vollkasko Versicherung von Hepster
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                <v-row>
                  <v-col cols="12" sm="12" md="10">
                    <table class="mb-1">
                      <tr>
                        <td><v-icon class="highlight">{{icons.check}}</v-icon></td>
                        <td>
                          20 % Ermäßigung auf alle Produkte im Shop des E-Bike Weltverbandes
                        </td>
                      </tr>
                    </table>
                    <table class="mb-1">
                      <tr>
                        <td><v-icon class="highlight">{{icons.check}}</v-icon></td>
                        <td>
                          E-Bike Newsletter – immer TOP informiert (mehrmals im Jahr)
                        </td>
                      </tr>
                    </table>
                    <table class="mb-1">
                      <tr>
                        <td><v-icon class="highlight">{{icons.check}}</v-icon></td>
                        <td>
                          Dein Beitrag für den E-Bike Sport für Jedermann
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row class="member-card-container mt-6">
      <v-col cols="12" sm="12" md="6" class="pb-0">
        <div class="member-card">
          <h1 class="member-title member-title-distance text-center">
            DEINE <span class="member-title-primary">VORTEILE</span>
          </h1>
        </div>
        <div class="member-card member-card-gray">
          <div class="member-card-overlay" v-if="overlays.hoodie">
            <v-icon class="member-card-overlay-close" @click="overlays.hoodie = false">{{icons.close}}</v-icon>
            Im Rahmen der Mitgliedschaft erhältst Du ein offizielles E-Bike World Federation T-Shirt in
            limitierter Auflage │ Special Edition im Wert von Euro 39,00.  <br> <br>
            Du kannst wählen: <br> <br>
            <v-icon class="member-card-overlay-highlight">{{icons.check}}</v-icon> T-Shirt in schwarz oder weiß <br>
            <v-icon class="member-card-overlay-highlight">{{icons.check}}</v-icon> T-Shirt in Deiner Wunschgröße
          </div>
          <v-card @click="overlays.hoodie = true" :ripple="false">
            <v-img src="https://ebikeworldfederation.com/images/infobar/new/EBWF_Mitgliedschaft_Contentbild_Mud_Buff_01.jpg">
              <h1 class="member-title">1x Wir sind E-Bike – Multifunktionstuch + EBWF-Edition - Mudguard</h1>
              <p>(im Wert von Euro 39,00)</p>
            </v-img>
            <v-card-actions class="member-card-action member-card-action-bottom">
              <div>
                Mehr erfahren
                <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                  <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                </svg>
              </div>
            </v-card-actions>
          </v-card>
        </div>
        <div class="member-card member-card-top-small-offset">
          <h1 class="member-title member-title-distance text-center">
            DEINE <span class="member-title-primary">HERAUSFORDERUNG</span>
          </h1>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="pb-0">
        <div class="member-card member-card-gray">
          <div class="member-card-overlay" v-if="overlays.start">
            <v-icon class="member-card-overlay-close" @click="overlays.start = false">{{icons.close}}</v-icon>
            Aufgrund des großen Interesses an den verschiedenen Events des E-Bike Weltverbandes und des aus
            Sicherheitsgründen beschränkten Teilnehmerfeldes ist meist eine schnelle Anmeldung und Glück zur
            Sicherung des Startplatzes notwendig. <br> <br>
            Mit Hilfe der Mitgliedschaft garantieren wir Dir zu allen Events innerhalb der abgeschlossenen Laufzeit
            der Mitgliedschaft einen gesicherten garantierten Startplatz bis 7 Tage vor dem jeweiligen Event.
            Anmeldung zum jeweiligen Event bis 7 Tage vor dem jeweiligen Event notwendig. Nicht übertragbar auf
            eine andere Person. <br>
          </div>
          <v-card @click="overlays.start = true" :ripple="false">
            <v-img height="234"
                   src="https://ebikeworldfederation.com/images/member/EBWF_Mitgliedschaft_Contentbild_Startplatz_01.jpg">
              <h1 class="member-title white--text">Gesicherter Startplatz bei allen Events des E-Bike Weltverbandes</h1>
            </v-img>
            <v-card-actions class="member-card-action member-card-action-bottom member-card-action-white">
              <div>
                Mehr erfahren
                <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                  <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                </svg>
              </div>
            </v-card-actions>
          </v-card>
        </div>
        <div class="member-card member-card-gray member-card-distance">
          <div class="member-card-overlay" v-if="overlays.breakdown">
            <v-icon class="member-card-overlay-close" @click="overlays.breakdown = false">{{icons.close}}</v-icon>
            Sowohl beim Transport als auch auf der Fahrradtour - die Gefahr ist hoch, dass Dein E-Bike selbst oder im
            schlimmsten Fall der Akku beschädigt wird. Die Kosten für eine professionelle Reparatur oder ein neues
            E-Bike können sehr teuer werden. Um dieses finanzielle Risiko zu vermeiden, deckt daher eine gute
            E-Bike-Versicherung für Dich Sturz- und Unfallschäden, Transportschäden, Vandalismus, Verschleiß,
            Abhandenkommen, Elektronik- und Akkuschäden, Diebstahl, Raub und vieles mehr ab. Unser Partner hepster
            bietet Dir den vollen Rundum-Schutz für Dein E-Bike und übernimmt die Kosten für die Reparatur oder den
            Ersatz für ein gleichwertiges, neuwertiges E-Bike gleicher Art und Güte nach dem Prinzip der
            Neuwertentschädigung.  <br> <br>
            <strong>Dein Vorteil als Mitglied des E-Bike Weltverbandes</strong> <br> <br>
            Mitglieder des E-Bike Weltverbandes erhalten <strong>EXKLUSIV 20 % Ermäßigung</strong> beim Abschluss
            einer E-Bike Versicherung unseres Partners MOINsure GmbH (unter der Marke hepster). <br> <br>

            Beim Abschluss der Versicherung einfach den Gutschein-/ Rabattcode eingeben, den Du per Mail nach der
            Anmeldung zur Mitgliedschaft im E-Bike Weltverband bekommen hast (falls nicht vorhanden, bitte kurz eine
            Mail an <a href="mailto:info@ebikeworldfederation.com ">info@ebikeworldfederation.com</a> schreiben). <br> <br>

            <small>
              <strong>Hinweis</strong>: Weitere Informationen/ Rückfragen/ Schadensmeldung etc. werden vom
              Versicherungsnehmers MOINsure GmbH (unter der Marke hepster) [Verlinkung zu
              <a href="https://buchung.hepster.com/e-bike-versicherung" target="_blank">
                https://buchung.hepster.com/e-bike-versicherung
              </a>] direkt geleistet. Nach Abschluss der E-Bike Versicherung erhältst Du Zugang zu Deinem hepster
              Kundenkonto. Dort hast Du Zugriff auf alle Deine Verträge und Dokumente und kannst diese bei Bedarf
              ganz einfach noch einmal downloaden.
            </small>
          </div>
          <v-card @click="overlays.breakdown = true" :ripple="false">
            <v-img height="234"
                   src="https://ebikeworldfederation.com/images/infobar/new/EBWF_Mitgliedschaft_Contentbild_Schutzbrief_01.jpg">
              <h1 class="member-title white--text">20 % Exklusive Ermäßigung auf E-Bike Vollkasko Versicherung</h1>
            </v-img>
            <v-card-actions class="member-card-action member-card-action-bottom member-card-action-white">
              <div>
                Mehr erfahren
                <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                  <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                </svg>
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" class="member-picture-separator pt-0 pb-0">
        <v-img src="https://ebikeworldfederation.com/images/member/EBWF_Mitgliedschaft_Zwischenheaderbild_01_01.jpg"/>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
        <div class="member-card member-card-gray member-card-top-offset">
          <div class="member-card-overlay" v-if="overlays.shop">
            <v-icon class="member-card-overlay-close" @click="overlays.shop = false">{{icons.close}}</v-icon>
            Als Mitglied bieten wir Dir 20 % Ermäßigung auf alle Produkte im offiziellen Shop
            <a href="https://wirsindebike.at/" target="_blank">www.wirsindebike.at</a> des E-Bike Weltverbandes.
            <br/> <br/>
            Nach Anmeldung zur Mitgliedschaft erhältst Du eine einen entsprechenden Ermäßigungscode hierfür.
          </div>
          <v-card @click="overlays.shop = true" :ripple="false">
            <v-img height="234"
                   src="https://ebikeworldfederation.com/images/member/EBWF_Mitgliedschaft_Contentbild_Produkte_01.jpg">
              <h1 class="member-title">
                20% Ermäßigung auf alle Produkte des E-Bike Weltverbandes
              </h1>
              <p><a href="https://wirsindebike.at/" target="_blank">www.wirsindebike.at</a></p>
            </v-img>
            <v-card-actions class="member-card-action member-card-action-bottom">
              <div>
                Mehr erfahren
                <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                  <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                </svg>
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="pt-0">
        <div class="member-card member-card-gray member-card-distance">
          <div class="member-card-overlay" v-if="overlays.contribution">
            <v-icon class="member-card-overlay-close" @click="overlays.contribution = false">{{icons.close}}</v-icon>
            Hehres Ziel der E-Bike World Federation (EBWF) als E-Bike Weltverband ist es, Aufklärungsarbeit über,
            sowie die Förderung und die Koordinierung von Projekten mit Fahrräder/n mit elektrischer Unterstützung
            und deren Versorgung durch (grüne) Energie zu leisten. <br/> <br/>
            Gemäß dem Motto "Everyone is a winner" unterstützen wir E-Bike Erlebnisse zwischen Wettbewerb und Genuss
            und gestalten innovative Projekte im Bereich Fahrsicherheit und Fahrtrainings. Mit Hilfe Deiner
            Mitgliedschaft unterstützt Du uns diese Ziele zu erreichen. <br/> <br/>
          </div>
          <v-card @click="overlays.contribution = true" :ripple="false">
            <v-img height="234"
                   src="https://ebikeworldfederation.com/images/member/EBWF_Mitgliedschaft_Contentbild_Dein_Beitrag_01.jpg">
              <h1 class="member-title">
                Dein Beitrag für den E-Bike Sport für Jedermann
              </h1>
            </v-img>
            <v-card-actions class="member-card-action member-card-action-bottom member-card-action-white">
              <div>
                Mehr erfahren
                <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                  <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                </svg>
              </div>
            </v-card-actions>
          </v-card>
        </div>
        <div class="member-card member-card-gray member-card-distance">
          <div class="member-card-overlay" v-if="overlays.newsletter">
            <v-icon class="member-card-overlay-close" @click="overlays.newsletter = false">{{icons.close}}</v-icon>
            Bleib‘ auf dem Laufenden. Verpass‘ keine Aktion, kein Gewinnspiel, kein Fach-/ Insiderwissen.
            Im Rahmen der EBWF Mitgliedschaft erhältst Du innerhalb der abgeschlossenen Laufzeit automatisiert
            unseren Newsletter „Everyone a winner“ mit Informationen rund ums E-Bike, einmalige Aktionen sowie
            die Chance zur Teilnahme an Gewinnspielen. Versand E-Bike Newsletter (max. 4 x pro Kalenderjahr).
          </div>
          <v-card @click="overlays.newsletter = true" :ripple="false">
            <v-img height="234"
                   src="https://ebikeworldfederation.com/images/member/EBWF_Mitgliedschaft_Contentbild_Events_01.jpg">
              <h1 class="member-title">
                <span class="member-title-primary">E-BIKE NEWSLETTER</span> <br/> – IMMER TOP INFORMIERT
              </h1>
            </v-img>
            <v-card-actions class="member-card-action member-card-action-bottom">
              <div>
                Mehr erfahren
                <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve">
                      <path style="fill:#444444;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                        C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                        C31.4,8.2,31.4,7.9,31.3,7.7"/>
                    </svg>
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="8" offset-xl="2">
        <v-row class="text-left">
          <v-col cols="12">
            <h1 class="member-title">
              {{$t('home.our')}}
              <span class="member-title-primary">{{$t('home.partner')}}</span>
            </h1>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <partners />
  </div>
</template>

<script>
  import {mdiChevronRight, mdiTools, mdiFrequentlyAskedQuestions, mdiAccountCardDetailsOutline, mdiCheck,
    mdiCancel, mdiBicycle,mdiFilePdfOutline, mdiCalendar, mdiClose, mdiCircleMedium} from '@mdi/js';
  import Partners from "./Partners";
  export default {
    components: { Partners },
    data () {
      return {
        icons: {
          right: mdiChevronRight,
          tools: mdiTools,
          faq: mdiFrequentlyAskedQuestions,
          card: mdiAccountCardDetailsOutline,
          check: mdiCheck,
          cancel: mdiCancel,
          bike: mdiBicycle,
          pdf: mdiFilePdfOutline,
          calendar: mdiCalendar,
          close: mdiClose,
          circle: mdiCircleMedium,
        },
        overlays: {
          hoodie: false,
          start: false,
          bikeEnergy: false,
          breakdown: false,
          insurance: false,
          shop: false,
          contribution: false,
          newsletter: false,
          startVoucher: false,
        },
      }
    },
  };
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
.member-title {
  font-weight: 600;
  font-style: italic;
  font-size: 2.2em;
  letter-spacing: 0;
  word-break: keep-all !important;
  .member-title-primary {
    color: $primary;
  }
}
</style>
